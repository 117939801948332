var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "input-field col s12" }, [
        _c("i", { staticClass: "material-icons prefix" }, [
          _vm._v("question_answer")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputQuestion,
              expression: "inputQuestion"
            }
          ],
          staticClass: "validate",
          attrs: { id: "question", type: "text" },
          domProps: { value: _vm.inputQuestion },
          on: {
            keypress: function($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.showWheel($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.inputQuestion = $event.target.value
            }
          }
        }),
        _c("label", { attrs: { for: "question" } }, [
          _vm._v("Ask Wheel of 8 Ball a yes or no question")
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s12 center" }, [
        _c("p", { staticClass: "flow-text" }, [
          _vm._v("Magic 8 Wheel, " + _vm._s(_vm.question) + "?")
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "center" }, [
        _c(
          "button",
          {
            staticClass: "btn waves-effect waves-light btn-large",
            class: _vm.animateAskButton,
            attrs: { id: "askButton" },
            on: { click: _vm.showWheel }
          },
          [_vm._v("Ask!")]
        )
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass: "center",
          class: _vm.animateWheel,
          attrs: { id: "spinWheel" }
        },
        [
          _c("i", { staticClass: "material-icons large" }, [
            _vm._v("arrow_downward")
          ]),
          _c("br"),
          _c(
            "canvas",
            { attrs: { id: "canvas", width: "500", height: "500" } },
            [_vm._v("Canvas not supported, use another browser.")]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass: "center",
          class: _vm.animateAnswer,
          attrs: { id: "answer" }
        },
        [
          _c("p", { staticClass: "flow-text" }, [
            _vm._v('Magic 8 Wheel says, "' + _vm._s(_vm.answer) + '."')
          ]),
          _c("br"),
          _c(
            "button",
            {
              staticClass: "btn waves-effect waves-light btn-large",
              attrs: { id: "resetButton" },
              on: { click: _vm.resetQuestion }
            },
            [_vm._v("Ask another question!")]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/home/taylor/html/wheelOfMagic8Fortune/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/home/taylor/html/wheelOfMagic8Fortune/node_modules/vue-hot-reload-api/dist/index.js").rerender('c3fd4fe0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }