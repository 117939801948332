<template lang="pug">
div
    Nav
    Content
</template>

<script>
import Nav from './Nav.vue';
import Content from './Content.vue';

export default {
    components: {
        Nav,
        Content
    }
}
</script>