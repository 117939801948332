<template lang="pug">
nav
    .nav-wrapper
        a(href="#" class="brand-logo center") {{ title }}
</template>

<script>
export default {
    data() {
        return {
            title: "Wheel of 8 Ball"
        }
    }
}
</script>
