var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", [
    _c("div", { staticClass: "nav-wrapper" }, [
      _c("a", { staticClass: "brand-logo center", attrs: { href: "#" } }, [
        _vm._v(_vm._s(_vm.title))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/home/taylor/html/wheelOfMagic8Fortune/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/home/taylor/html/wheelOfMagic8Fortune/node_modules/vue-hot-reload-api/dist/index.js").rerender('5899ff3a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }